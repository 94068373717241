import React, { useState } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { apiAdminGetUserById } from '../../../services/api/admin';
import { apiGetOptionstInfo } from '../../../services/api/options';
import { getPlatformList } from '../../../services/api/admin';
import dayjs from 'dayjs';
// prop userId, onClose, onEdit

class EditUserPlatformModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.user_id != null,
            userInfo: null,
            isLoading: true,
            isOptionLoaded: false,
            platformRoleOptions: [],
            originUserPlatform: {},
            updatedUserPlatform: {},
        };
    }

    elementGroupClassName = 'flex flex-column gap-2 mb-2'

    titleOption = [
        {
            en: 'Mr.',
            th: 'นาย',
        },
        {
            en: 'Ms.',
            th: 'นาง/นางสาว',
        },
    ]


    componentDidMount() {
        this.getOptionInfo().then(() => {

            if (this.props.user_id != null) {
                this.getUserInfo();
            }
        });
    }

    getOptionInfo = async () => {
        try {
            const result = await getPlatformList();
            this.setState({ platformRoleOptions: result.data, isOptionLoaded: true });
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.user_id !== prevProps.user_id) {
            this.setState({ showModal: this.props.user_id != null });
            if (this.props.user_id != null) {
                this.getUserInfo();
            }
        }
    }

    getUserInfo = () => {
        this.setState({ isLoading: true });
        apiAdminGetUserById(this.props.user_id).then((result) => {

            //normalize user platform
            let userPlatforms = {};
            let userPlatformsRoleId = {};

            this.state.platformRoleOptions.forEach((platform) => {

                let up = result.data.platform_users.find((p) => p.platform_id === platform.id);
                if (up) {
                    userPlatforms[platform.id] = { ...up.platform_role, updated_at: up.updated_at, last_synced_at: up.last_synced_at };
                    userPlatformsRoleId[platform.id] = up.platform_role_id;
                } else {
                    userPlatforms[platform.id] = null;
                    userPlatformsRoleId[platform.id] = null;
                }
            });

            console.log('userPlatforms', userPlatforms);

            this.setState({
                userInfo: result.data,
                originUserPlatform: userPlatforms,
                updatedUserPlatform: userPlatformsRoleId,
                isLoading: false
            });
        });
    }

    handleClose = () => {
        this.setState({ showModal: false });
        this.props.onClose();
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true })

        //detect changed platform role
        let changed = false;
        let changedPlatform = [];
        for (let key in this.state.originUserPlatform) {
            const oldRoleId = this.state.originUserPlatform[key] ? this.state.originUserPlatform[key].id : null;
            const oldUserPlatform = this.state.userInfo.platform_users.find((p) => p.platform_id === key);

            if (oldRoleId !== this.state.updatedUserPlatform[key]) {
                changed = true;
                let changeType = 'update';
                if (oldRoleId == null) {
                    changeType = 'create';
                } else if (this.state.updatedUserPlatform[key] == "") {
                    changeType = 'delete';
                }

                changedPlatform.push({
                    platform_id: key,
                    user_platform_id: oldUserPlatform ? oldUserPlatform.id : null,
                    platform_role_id: this.state.updatedUserPlatform[key],
                    change_type: changeType
                });
            }
        }

        if (!changed) {
            alert('ไม่มีการเปลี่ยนแปลง');
            this.setState({ isLoading: false });
            return;
        }

        // Handle form submission logic here
        this.props.onEdit(changedPlatform).then(() => {
            this.setState({ isLoading: false, showModal: false })
        });
    };

    handleForceSync = (platformId) => {

        //force sync is sending update request to platform user

        const syncedPlatform = [
            {
                platform_id: platformId,
                user_platform_id: this.state.userInfo.platform_users.find((p) => p.platform_id === platformId).id,
                platform_role_id: this.state.updatedUserPlatform[platformId],
                change_type: 'update'
            }
        ]

        this.props.onEdit(syncedPlatform).then(() => {
            this.setState({ isLoading: false, showModal: false })
        });
    }

    render() {
        const { showModal, isOptionLoaded, userInfo, platformRoleOptions } = this.state;



        return <>
            {(isOptionLoaded && userInfo != null) &&
                <Modal show={showModal && isOptionLoaded} onHide={this.handleClose} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Platform</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Table striped bordered hover>

                                <thead>
                                    <tr>
                                        <th>Platform Name</th>
                                        <th>Current Role</th>
                                        <th>Updated Role</th>
                                        <th>Updated At</th>
                                        <th>Last Synced At</th>
                                        <th>Force Sync</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        platformRoleOptions.map((platform, index) => {
                                            return <tr key={index}>
                                                <td>{platform.name}</td>
                                                <td>
                                                    {this.state.originUserPlatform[platform.id] ?
                                                        this.state.originUserPlatform[platform.id].role_name :
                                                        "ไม่เปิดใช้งาน"}
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={this.state.updatedUserPlatform[platform.id]}
                                                        onChange={(e) => {
                                                            let up = { ...this.state.updatedUserPlatform };
                                                            up[platform.id] = e.target.value;
                                                            this.setState({ updatedUserPlatform: up });
                                                        }}
                                                    >
                                                        <option value="">ไม่เปิดใช้งาน</option>
                                                        {
                                                            platform.platform_roles.map((role, index) => {
                                                                return <option key={index} value={role.id}>{role.role_name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    {this.state.originUserPlatform[platform.id] ?
                                                        dayjs(this.state.originUserPlatform[platform.id].updated_at).format('YYYY-MM-DD HH:mm:ss')
                                                        : "ไม่เปิดใช้งาน"}
                                                </td>
                                                <td>
                                                    {this.state.originUserPlatform[platform.id] ?
                                                        (this.state.originUserPlatform[platform.id].last_synced_at ? dayjs(this.state.originUserPlatform[platform.id].last_synced_at).format('YYYY-MM-DD HH:mm:ss') : "---")
                                                        : "ไม่เปิดใช้งาน"}
                                                </td>
                                                <td>
                                                    {this.state.originUserPlatform[platform.id] &&
                                                        <Button variant="success" size="sm" onClick={() => this.handleForceSync(platform.id)} type="button">
                                                            Force Sync
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Button variant="success" type="submit">
                                บันทึก
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            }
        </>
    }
}

export default EditUserPlatformModal;