import { apiGetInfo } from "../../lib/services/api/user";


function GoogleLogin() {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    let decodeToken = JSON.parse(atob(token));

    async function setToken() {
        localStorage.setItem("token", decodeToken.token);
        localStorage.setItem("expire", decodeToken.expire_at);
    }

    async function setUser() {
       await apiGetInfo();
    }
    
    function changePage() {
        setTimeout(() => {
            window.location.replace("/app/dashboard");
        }, 2000);
    }

    async function executeFunctions() {
        await setToken();
        await setUser();
        changePage();
    }

    executeFunctions();
}

export default GoogleLogin;
