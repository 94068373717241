import React, { useState ,useEffect } from 'react'
import { apiGetAuthorizeCode, apiLoginMoocAuthCode, apiLoginWithAuthCode , apiLogout} from '../../lib/services/api/auth'
import HeaderTop from '../../lib/components/frame/header'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Footer from '../../lib/components/frame/footer'


const UserRegister = () => {
	const [loading, setLoading] = useState(true)
	const imgClick = async (id, event) => {
		console.log('id', id)
		if (id === 'mooc') {
			setTimeout(() => {
				window.location.assign('/app/user_list_mooc')
			}, 1000)
		} else if (id === 'skillmeo') {
			setTimeout(() => {
				window.location.assign('/app/user_list_skillmeo')
			}, 1000)
		} else {
            setTimeout(() => {
				window.location.assign('/admin/user_management')
			}, 1000)
        }
	}

	async function fetchtokenSkillmeo() {
        const responseCode = await apiGetAuthorizeCode(process.env.REACT_APP_SKILLMEO_APP_CODE)

        if(responseCode.status >=200) {
            const authorize_code = responseCode.data.authorize_code
            const dataAuthCode ={
                "authorize_code" : authorize_code
            }
            try{
                const response =  await apiLoginWithAuthCode(dataAuthCode)
                const { token, token_expired_at, refresh_token, refresh_token_expired_at } = response.data
                if(response.status  >= 200){
                    console.log("Success_skillmeo" ,response)
                  
                    localStorage.setItem('skillmeo_token', token)
                    localStorage.setItem('skillmeo_expired', token_expired_at)
                    localStorage.setItem('skillmeo_refresh_token', refresh_token)
                    localStorage.setItem('skillmeo_refresh_token_expired_at', refresh_token_expired_at)
                }else{
                    console.error("Error", response)
                }
         
            }catch (error){
              console.error("An error occurred" , error)
            }

        }else{
            console.error("Authorization Code not found")
        }
    }

    async function fetchTokenMooc(){
        const responseCode = await apiGetAuthorizeCode(process.env.REACT_APP_MOOC_APP_CODE)
        

        if(responseCode.status >=200) {
            const authorize_code = responseCode.data.authorize_code
            const dataAuthCode ={
                "authorize_code" : authorize_code
            }
            try{
                const response =  await apiLoginMoocAuthCode(dataAuthCode)
               
                const { token, expire, refresh_token, refresh_expire  } = response.data
                if(response.status  >= 200){
                    console.log("Success_mooc" ,response)
                  
                    localStorage.setItem('mooc_token', token)
                    localStorage.setItem('mooc_expired', expire)
                    localStorage.setItem('mooc_refresh_token', refresh_token)
                    localStorage.setItem('mooc_refresh_token_expired_at', refresh_expire)
                }else{
                    console.error("Error", response)
                }
         
            }catch (error){
              console.error("An error occurred" , error)
            }

        }else{
            console.error("Authorization Code not found")
        }
    }
    useEffect(() => {
        setLoading(false)
        if(!localStorage.getItem('mooc_token')) {
            fetchTokenMooc();
        }
        if(!localStorage.getItem('skillmeo_token')) {
            fetchtokenSkillmeo();
        }
    },[]);

	return (
		<>
			<HeaderTop showProfile='true'></HeaderTop>
			<Container className='container-size' fluid>
				<Row className='bg-white' xs={1} md={2}>
					<Col className='text-center'>
						<Image src='/shared/main_icon.png' className='w-75' alt='' />
					</Col>
					<Col>
						<div className='text-container mt-4 mb-4'>
							<h2>User Register</h2>
							พัฒนาศักยภาพส่วนบุคคล เพื่อประสิทธิผลองค์รวมขององค์กร ผู้ใช้งานสามารถศึกษาการใช้งาน
							โดยการดาวน์โหลดคู่มือการใช้งานจากเมนูด้านบน
						</div>
						<Row className='mb-5 ' sm={2} md={2} xl={4}>
                            <Col className='grid-item' onClick={() => imgClick('sso')}>
                                <Image  className={!loading ? "img" : "border-none"} src='/linked_platform_sso.png' alt='' />
                            </Col>
							<Col className='grid-item' onClick={() => imgClick('mooc')}>
								<Image  className={!loading ? "img" : "border-none"} src='/linked_platform_online-course.png' alt='' />
							</Col>
							<Col className='grid-item' onClick={() => imgClick('skillmeo')}>
								<Image  className={!loading ? "img" : "border-none"} src='/linked_platform_skillmeo.png' alt='' />
							</Col>
							<Col className='d-none d-xl-block'></Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<Footer></Footer>
		</>
	)
}

export default UserRegister
