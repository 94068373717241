import React, { useState, useEffect } from 'react';
import {Button} from "react-bootstrap";
import Swal from 'sweetalert2'; 
import HeaderTop from '../../lib/components/frame/header';
import { Card } from 'primereact/card';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Divider } from 'primereact/divider';
import { apiCheckForgotPasswordToken, apiRedeemForgotPasswordToken } from '../../lib/services/api/auth';

function AuthRedeemForgorPasswordTokenFrm(props) {
    const { token } = useParams();
    const [state, setState] = useState({
        token: token,
        is_token_valid: null,
        user: null,
        password: '',
        password_re: '',
    });

    const elementGroupClassName = "flex flex-column gap-2 mb-2";
    const myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        setState({ ...state, [nam]: val });
    };

    const activeChangePassword = async () => {

        if (state.password.length < 8) {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านต้องมีความยาว 8 ตัวอักษร',
            });
            return;
        }

        if (state.password !== state.password_re) {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
            });
            return;
        }

        try {
            await apiRedeemForgotPasswordToken(state.token, state.password);
            await Swal.fire({
                icon: 'success',
                text: 'เปลี่ยนแปลงรหัสผ่านสำเร็จ',
            });
            props.history.push('/login');
        } catch (e) {
            console.log('e', e);
            await Swal.fire({
                icon: 'error',
                text: 'พบปัญหาไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาทดลองเปลี่ยนรหัสผ่านใหม่',
            });
        }
    };

    const checkToken = async () => {
        try {
            let user = await apiCheckForgotPasswordToken(state.token);
            setState({ ...state, is_token_valid: true, user: user });
        } catch (e) {
            console.error('e', e);
            setState({ ...state, is_token_valid: false });
        }
    };

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <>
            <HeaderTop activeMenu={"login"}></HeaderTop>
            {state.is_token_valid !== null && (
            <Card className="p-shadow-4">
                <div className="p-fluid ">
                    <h4 className="p-3 text-center text-secondary">รีเซ็ตรหัสผ่าน</h4>
                    <Divider />
                    <div className="p-fieldset ">  
                    {state.is_token_valid === true ? (
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6 mt-3">
                            <div className={elementGroupClassName}>
                                <label htmlFor="userName">ชื่อผู้ใช้งาน</label>
                                <InputText
                                    id="userName"
                                    type="text"
                                    readOnly
                                    value={state.user}
                                />
                            </div>
                            <div className={elementGroupClassName}>
                                <label htmlFor="password">รหัสผ่าน</label>
                                <InputText
                                    id="password"
                                    type="password"
                                    name="password"
                                    onChange={myChangeHandler}
                                />
                                <Message
                                    severity="success"
                                    icon
                                    text="รหัสผ่านต้องมีความยาว 8 ตัวอักษร"
                                />
                            </div>
                            <div className={elementGroupClassName}>
                                <label htmlFor="passwordRe">ยืนยันรหัสผ่าน</label>
                                <InputText
                                    id="passwordRe"
                                    type="password"
                                    name="password_re"
                                    onChange={myChangeHandler}
                                />
                            </div>
                            <div className="flex justify-content-center p-4">
                                <Button  variant="secondary" type="button"  className="btn-register-submit"  onClick={activeChangePassword} >
                                    เปลี่ยนรหัสผ่าน
                                </Button>
                            </div>
                        </div>
                    ) : ( 
                        <Message
                        severity="secondary"
                        text="ลิงก์หมดอายุ หรือถูกใช้ในการเปลี่ยนรหัสผ่านไปแล้ว"
                        style={{ textAlign: 'center', backgroundColor: '#e8e8e8', border: '1px solid #d3d3d3', padding: '10px', borderRadius: '4px' }}
                    /> 
                    )}
                    </div>
                </div>
            </Card>
              )}
        </>
    );
}

export default AuthRedeemForgorPasswordTokenFrm;
