import React, { Component } from 'react'
import Swal from 'sweetalert2'
import { apiLogin } from '../../lib/services/api/auth'
import { apiGetInfo } from '../../lib/services/api/user'
import { Container, Form, Col, Row, Button, Card, Image } from 'react-bootstrap'
import Footer from '../../lib/components/frame/footer'
import HeaderTop from '../../lib/components/frame/header'
import { Link } from 'react-router-dom'
import { FcGoogle } from 'react-icons/fc'

class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			username: '',
			password: '',
		}
	}

	myChangeHandler = (event) => {
		const { name, value } = event.target
		this.setState({ [name]: value })
	}

	handleGoogleLogin = async () => {
		window.location.replace(process.env.REACT_APP_MAIN_ENDPOINT + '/auth/login_google')
	}
	
	activeLogin = async (e) => {
		e.preventDefault()
		const { username, password } = this.state

		if (!username || !password) {
			Swal.fire({
				icon: 'error',
				text: 'กรุณาระบุชื่อผู้ใช้งานและรหัสผ่าน',
			})
			return
		}

		try {
			const result = await apiLogin({ user_name: username, password })

			if (result.status >= 200 && result.status <= 250 && result.data.token) {
				localStorage.setItem('token', result.data.token)
				localStorage.setItem('expire', result.data.expire_at)

				const user_info = await apiGetInfo()
				console.log(user_info)
				Swal.fire({
					icon: 'success',
					text: 'เข้าสู่ระบบสำเร็จ',
					confirmButtonColor: '#28825A',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.replace('/app/dashboard')
					}
				})
			} else {
				if (result.is_found === null) {
					Swal.fire({
						icon: 'error',
						text: 'ผู้ใช้งานนี้มีความพยายามเข้าระบบหลายครั้ง กรุณาทดลองเข้าระบบใหม่หลังจาก 5 นาที',
					})
				} else {
					Swal.fire({
						icon: 'error',
						text: 'ชื่อหรือรหัสผ่านไม่ถูกต้อง',
					})
				}
			}
		} catch (error) {
			console.error('Error logging in:', error)
		}
	}

	render() {
		const { username, password } = this.state

		// Check if the user is already logged in and redirect to the dashboard
		if (localStorage.getItem('token')) {
			window.location.replace('/app/dashboard')
			return null
		}

		return (
			<>
				<HeaderTop activeMenu={'login'} />

				<Container className='container-size' fluid>
					<Row className='d-flex justify-content-center align-items-center'>
						<Col className='d-none d-xl-block text-center'>
							<Image src='shared/main_icon.png' width={600} alt='' />
						</Col>
						<Col>
							<Card className='align-items-center bg-transparent border-0'>
								<Card.Body>
									<Form className='wrapper' onSubmit={this.activeLogin}>
										<Form.Group className='form-box login'>
											<h2>เข้าสู่ระบบ</h2>
											<div>
											{ process.env.REACT_APP_ALLOW_USERNAME_PASSWORD_LOGIN === 'true' &&
                                                    <>
                                                
                                                    <div className='input-box mt-3'>
                                                        <span className='icon'>
                                                            <ion-icon name='mail'></ion-icon>
                                                        </span>
                                                        <input type='text' name='username' value={username} onChange={this.myChangeHandler} required />
                                                        <Form.Label>ชื่อผู้ใช้</Form.Label>
                                                    </div>
                                                    <div className='input-box'>
                                                        <span className='icon'>
                                                            <ion-icon name='lock-closed'></ion-icon>
                                                        </span>
                                                        <input type='password' name='password' value={password} onChange={this.myChangeHandler} required />
                                                        <Form.Label>รหัสผ่าน</Form.Label>
                                                    </div>
                                                    <Row className='login-register' xs={2}>
                                                        <Col className='d-flex justify-content-start pl-0'>
                                                            <Form.Check type='switch' id='remember-forget' label='จดจำผู้ใช้งาน' className='remember-forgot d-inline-block' />
                                                        </Col>
                                                        <Col className='d-flex justify-content-end pr-0'>
                                                            <Link to='/forgot_password' className='remember-forgot d-inline-block'>
                                                                ลืมรหัสผ่าน
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                    <Button className='btn-login mb-3' variant='success' type='submit'>
                                                        เข้าสู่ระบบ
                                                    </Button>
                                                    </>
                                                }
                                                {
                                                    process.env.REACT_APP_ALLOW_SELF_REGISTER === 'true' &&
                                                    <div className='login-register'>
                                                        <p>
                                                            ยังไม่มีบัญชีผู้ใช้งาน?{' '}
                                                            <Link to='/register' className='register-link'>
                                                                ลงทะเบียน
                                                            </Link>
                                                        </p>
                                                    </div>
                                                }
                                                { 
                                                    process.env.REACT_APP_ALLOW_USERNAME_PASSWORD_LOGIN === 'true' ?

                                                    <div class='google-login-dashed-line'>
                                                        <div class='google-login-dashes'></div>
                                                        <div class='google-login-text'>or sign in with</div>
                                                        <div class='google-login-dashes'></div>
                                                    </div>
                                                    :
                                                    <div style={{marginTop: '64px'}}></div>
                                                }
												<div class='google-login-button mt-2'>
													<Button className='btn-google-login mt-2' type='button' onClick={this.handleGoogleLogin}>
														<FcGoogle className='mr-2'></FcGoogle> Google
													</Button>
												</div>
											</div>
										</Form.Group>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
				<Footer />
			</>
		)
	}
}

export default Login
