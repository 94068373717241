import React from 'react'
import HeaderTop from '../../lib/components/frame/header'
import { Container } from 'react-bootstrap'
import Footer from '../../lib/components/frame/footer'
import UserTable from '../../lib/components/auth/users_table'


const UserListMooc = () => {
	return (
		<>
			<HeaderTop showProfile='true'></HeaderTop>
			<Container className='container-size' fluid>
					<UserTable props="Mooc" />
			</Container>
			<Footer/>
		</>
	)
}

export default UserListMooc
