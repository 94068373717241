import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Route from './router';

function App() {
  return (
    <Route />
  );
}

export default App;
