import React from 'react'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { apiGetOptionstInfo } from '../../lib/services/api/options'
import { registerUser } from '../../lib/services/api/register'
import './style/login.css'
import { Button, Form, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from '../../lib/components/frame/footer'
import HeaderTop from '../../lib/components/frame/header'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Dropdown } from 'primereact/dropdown'
import { Card } from 'primereact/card'
import { InputMask } from 'primereact/inputmask'

const Register = () => {
	const [selectedRole, setSelectedRole] = useState('')
	const [selectedSchool, setSelectedSchool] = useState()
	const [selectedProvince, setSelectedProvince] = useState()
	const [selectedDistrictOffice, setSelectedDistrictOffice] = useState()
	const [selectedArea, setSelectedArea] = useState()
	const [roleOptions, setRoleOptions] = useState([])
	const [schoolOptions, setSchoolOptions] = useState([])
	const [provinceOptions, setProvinceOptions] = useState([])
	const [districtOptions, setDistrictOptions] = useState([])
	const [districtAllOptions, setDistrictAllOptions] = useState([])
	const [areaOption, setAreaOption] = useState([])

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [title, setTitle] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')
	const [position, setPosition] = useState('')
	const [tel, setTel] = useState('')
	const [cardid, setCardId] = useState('')

	const titleOption = [
		{
			en: 'Mr.',
			th: 'นาย',
		},
		{
			en: 'Ms.',
			th: 'นาง/นางสาว',
		},
	]
	// Add a function to update district options when a province is selected
	const updateDistrictOptions = (provinceCode) => {
		if (districtAllOptions[provinceCode]) {
			setDistrictOptions(districtAllOptions[provinceCode])
		} else {
			setDistrictOptions([]) // Clear district options if no data is available for the selected province
		}
	}
	const fetchData = async () => {
		try {
			let options = await apiGetOptionstInfo()
			if (options && options.status === 200) {
				let roles = options.data.roles.map(({ id, role_display_name }) => ({ name: role_display_name, code: parseInt(id) }))
				let schools = options.data.schools.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
				let provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
				let schoolareas = options.data.areas.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))
				
				setSelectedRole(roles[0])
				setRoleOptions(roles)
				setSchoolOptions(schools)
				setProvinceOptions(provinces)
				setAreaOption(schoolareas)
				setDistrictAllOptions(options.data.districts)
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const elementGroupClassName = 'flex flex-column gap-2 mb-2'

	const activeRegister = async (e) => {
		e.preventDefault()
		if (username.length === 0) {
			Swal.fire({
				icon: 'error',
				text: 'กรุณาระบุชื่อผู้ใช้งาน',
			})
			return
		}

		if (password.length < 8) {
			Swal.fire({
				icon: 'error',
				text: 'รหัสผ่านต้องมีความยาว 8 ตัวอักษร',
			})
			return
		}

		let payload = {
			title: title.en,
			first_name: firstname,
			last_name: lastname,
			email: email,
			tel: tel,
			user_name: username,
			password: password,
			school_id: selectedSchool?.code,
			school_area_id: selectedArea?.code,
			role_id: selectedRole?.code,
			ssn: cardid,
			province_id: selectedProvince?.code,
			district_id: selectedDistrictOffice?.district_id,
			position: position,
		}

		let result = await registerUser(payload)

		console.log(result)

		if (result.status >= 200 && result.status <= 250) {
			await Swal.fire({
				icon: 'success',
				text: 'สร้างผู้ใช้งานสำเร็จ',
			})
			window.location.replace('/login')
		} else {
			await Swal.fire({
				icon: 'error',
				text: result.data.message,
			})
		}
	}

	return (
		<>
			<HeaderTop activeMenu={'login'}></HeaderTop>
			<Container className='container-size' fluid>
				<Card>
					<Form onSubmit={activeRegister}>
						<h4 className='p-3 text-center text-secondary'>ข้อมูลผู้ใช้งาน</h4>
						<hr className='bg-success'></hr>
						<div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
							<div className={elementGroupClassName}>
								<label htmlFor='role'>Role</label>
								<Dropdown
									value={selectedRole}
									onChange={(e) => setSelectedRole(e.value)}
									options={roleOptions}
									optionLabel='name'
									placeholder='เลือก Role'
									className='w-full'
									required
								/>
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='username'>ชื่อผู้ใช้งาน</label>
								<InputText id='username' onChange={(e) => setUsername(e.target.value)} required />
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='password'>รหัสผ่าน</label>
								<Password id='password' aria-describedby='password-help' onChange={(e) => setPassword(e.target.value)} required />
								<small id='password-help'>รหัสผ่านต้องมีความยาว 8 ตัวอักษร</small>
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='confirm_password'>ยืนยันรหัสผ่าน</label>
								<Password id='confirm_password' required />
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='email'>อีเมล์</label>
								<InputText id='email' type='email' onChange={(e) => setEmail(e.target.value)} required />
							</div>
						</div>

						<h4 className='p-3 text-center text-secondary'>ข้อมูลส่วนตัว</h4>
						<hr className='bg-success'></hr>
						<div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
							<div className={elementGroupClassName}>
								<div className={elementGroupClassName}>
									<label htmlFor='title'>คำนำหน้า</label>
									<Dropdown id='title' value={title} options={titleOption} optionLabel='th' onChange={(e) => setTitle(e.target.value)} required />
								</div>
								<label htmlFor='name'>ชื่อ</label>
								<InputText id='name' onChange={(e) => setFirstname(e.target.value)} required />
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='surname'>นามสกุล</label>
								<InputText id='surname' onChange={(e) => setLastname(e.target.value)} required />
							</div>

							{selectedRole.code !== 1 && (
								<>
									<div className={elementGroupClassName}>
										<label htmlFor='school_name'>โรงเรียน</label>
										<Dropdown
											value={selectedSchool}
											onChange={(e) => setSelectedSchool(e.value)}
											options={schoolOptions}
											optionLabel='name'
											filter
											filterBy='name'
											placeholder='เลือก รหัส-ชื่อโรงเรียน'
											virtualScrollerOptions={{ itemSize: 25 }}
											className='w-full'
											required
										/>
									</div>
									<div className={elementGroupClassName}>
										<label htmlFor='district_office'>สำนักงานเขต</label>
										<Dropdown
											value={selectedArea}
											onChange={(e) => setSelectedArea(e.target.value)}
											options={areaOption}
											optionLabel='name'
											placeholder='เลือก สำนักงานเขต'
											className='w-full'
											filter
											required
										/>
									</div>
								</>
							)}

							<div className={elementGroupClassName}>
								<label htmlFor='province'>จังหวัด</label>
								<Dropdown
									value={selectedProvince}
									onChange={(e) => {
										setSelectedProvince(e.value)
										updateDistrictOptions(e.value.code) // Update district options based on the selected province
									}}
									options={provinceOptions}
									optionLabel='name'
									placeholder='เลือก จังหวัด'
									className='w-full'
									filter
									required
								/>
							</div>

							{selectedRole.code === 1 && (
								<>
									<div className={elementGroupClassName}>
										<label htmlFor='district'>เขต/อำเภอ</label>
										<Dropdown
											value={selectedDistrictOffice}
											onChange={(e) => setSelectedDistrictOffice(e.value)}
											options={districtOptions}
											optionLabel='district_name'
											placeholder='เลือก เขต/อำเภอ'
											virtualScrollerOptions={{ itemSize: 25 }}
											className='w-full'
											filter
											required
										/>
									</div>
								</>
							)}

							{selectedRole.code === 3 && (
								<div className={elementGroupClassName}>
									<label htmlFor='position'>ตำแหน่ง</label>
									<InputText id='position' value={position} onChange={(e) => setPosition(e.target.value)} required />
								</div>
							)}

							<div className={elementGroupClassName}>
								<label htmlFor='tel'>เบอร์โทรศัพท์</label>

								<InputMask value={tel} onChange={(e) => setTel(e.target.value)} mask='999-999-9999' placeholder='999-999-9999' required />
							</div>

							<div className={elementGroupClassName}>
								<label htmlFor='card_id'>หมายเลขบัตรประชาชน</label>
								<InputMask id='card_id' keyfilter='int' onChange={(e) => setCardId(e.target.value)} mask='9999999999999' required />
							</div>
						</div>

						<div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
							<div className='flex justify-content-center p-4'>
								<Button variant='secondary' type='submit' className='btn-register-submit'>
									สมัครสมาชิก
								</Button>
							</div>
						</div>
					</Form>
				</Card>
			</Container>
			<Footer></Footer>
		</>
	)
}

export default Register
