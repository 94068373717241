import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiAdminGetUserById } from '../../../services/api/admin';
import { apiGetOptionstInfo } from '../../../services/api/options';
import { apiGetAdminRoleList } from '../../../services/api/admin';
import { InputText } from 'primereact/inputtext'
// prop userId, onClose, onEdit

class EditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.user_id != null,
            userInfo: null,
            isLoading: true,
            isOptionLoaded: false,
            roleOptions: [],
            schoolOptions: [],
            provinceOptions: [],
            districtOptions: [],
            districtAllOptions: [],
            areaOptions: [],
            adminRoleOptions: [],
        };
    }

    elementGroupClassName = 'flex flex-column gap-2 mb-2'

    titleOption = [
        {
            en: 'Mr.',
            th: 'นาย',
        },
        {
            en: 'Ms.',
            th: 'นาง/นางสาว',
        },
    ]


    componentDidMount() {
        this.getOptionInfo()

        if (this.props.user_id != null) {
            this.getUserInfo();
        }
    }

    getOptionInfo = () => {
        try {
            apiGetAdminRoleList().then(result => {
                this.setState({ adminRoleOptions: result.data.map(({ id, role_display_name }) => ({ name: role_display_name, code: parseInt(id) })) })
                apiGetOptionstInfo().then(options => {
                    console.log("getOptionInfo", options)
                    if (options && options.status === 200) {
                        let roles = options.data.roles.map(({ id, role_display_name }) => ({ name: role_display_name, code: parseInt(id) }))
                        let schools = options.data.schools.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
                        let provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
                        let schoolareas = options.data.areas.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))
    
                        this.setState({
                            roleOptions: roles,
                            schoolOptions: schools,
                            provinceOptions: provinces,
                            areaOptions: schoolareas,
                            isOptionLoaded: true
                        })
                    }
                });
    
            } );

        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user_id !== prevProps.user_id) {
            this.setState({ showModal: this.props.user_id != null });
            if (this.props.user_id != null) {
                this.getUserInfo();
            }
        }
    }

    getUserInfo = () => {
        this.setState({ isLoading: true });
        apiAdminGetUserById(this.props.user_id).then((result) => {
            this.setState({ userInfo: result.data, isLoading: false });
        });
    }

    handleClose = () => {
        this.setState({ showModal: false });
        this.props.onClose();
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true })
        // Handle form submission logic here
        this.props.onEdit(this.state.userInfo).then(() => {
            this.setState({ isLoading: false, showModal: false })
        });
    };

    //--- Form Input Handlers
    setTitle = (e) => {
        this.setState({ userInfo: { ...this.state.userInfo, title: e.value } })
    }

    setFirstname = (e) => {
        this.setState({ userInfo: { ...this.state.userInfo, first_name: e.target.value } })
    }

    setLastname = (e) => {
        this.setState({ userInfo: { ...this.state.userInfo, last_name: e.target.value } })
    }

    setSelectedRole = (e) => {
        this.setState({ userInfo: { ...this.state.userInfo, role_id: parseInt(e.target.value) } })
    }

    render() {
        const { showModal, isOptionLoaded, userInfo, adminRoleOptions } = this.state;
        const { elementGroupClassName, titleOption } = this;



        return <>
            {(isOptionLoaded && userInfo != null) &&
                <Modal show={showModal && isOptionLoaded} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <div className={elementGroupClassName}>
                                <div className={elementGroupClassName}>
                                    <label htmlFor='title'>คำนำหน้า</label>
                                    <Form.Select size="sm" name='title' onChange={this.setTitle} value={userInfo?.title}>
                                        {titleOption.map((title, index) => {
                                            return <option key={index} value={title.th}>{title.th}</option>
                                        }
                                        )}
                                    </Form.Select>
                                </div>
                                <label htmlFor='name'>ชื่อ</label>
                                <InputText id='name' onChange={this.setFirstname} required value={userInfo?.first_name} />
                            </div>

                            <div className={elementGroupClassName}>
                                <label htmlFor='surname'>นามสกุล</label>
                                <InputText id='surname' onChange={this.setLastname} required value={userInfo?.last_name} />
                            </div>

                            <div className={elementGroupClassName}>
                                <label htmlFor='role'>Role</label>
                                <Form.Select size="sm" name='role' onChange={this.setSelectedRole} value={userInfo?.role_id}>
                                    {adminRoleOptions.map((role, index) => {
                                        return <option key={index} value={role.code}>{role.name}</option>
                                    }
                                    )}
                                </Form.Select>
                            </div>

                            <Button variant="success" type="submit">
                                บันทึก
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            }
        </>
    }
}

export default EditUserModal;