import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable, Column, Toast } from 'primereact'
import { apiGetUserList, apiAdminUpdateUser, getPlatformList, apiAddPlatformUser, apiUpdatePlatformUserRole, apiDeletePlatformUser, apiDeleteUser} from '../../services/api/admin'
import { apiGetOptionstInfo } from '../../services/api/options'
import EditUserModal from './modal/edit_user'
import EditUserPlatformModal from './modal/edit_user_platform'
import LogUserAccessPlatformModal from './modal/log_user_access'
import './style/auth_style.css'
import { Row, Col, Table, Form, Container, Badge, Button } from 'react-bootstrap'
import HeaderTop from '../frame/header'
import Footer from '../frame/footer'
import { Dropdown } from 'primereact/dropdown'

export default class UserTableSSO extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			options: null,
			schoolOptions:[],
			selectedSchool:null,
			users: [],
			plarforms: [],
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				number: { value: null, matchMode: FilterMatchMode.EQUALS },
				username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				fullname: { value: null, matchMode: FilterMatchMode.CONTAINS },
				role: { value: null, matchMode: FilterMatchMode.EQUALS },
			},
			showFilter:false,
			showSuspendDialog:false,
			isLoading: true,
			searchKeyword: "",
			searchSchoolId: 0,
			editInfoUserId: null,
			editPlatformUserId: null,
			showLogUserId: null,
			deleteUserId: null,
		}
	}

	componentDidMount() {
		this.fetchOptionData().then(() => {
			this.fetchPlatformData().then(() => {
				this.fetchUserData()
			})
		})
	}

	async fetchPlatformData() {
		const result = await getPlatformList();
		console.log("fetchPlatformData", result.data)
		this.setState({ plarforms: result.data })
	}



	async fetchOptionData() {
		const result = await apiGetOptionstInfo();
		console.log("fetchOptionData", result.data)
		this.setState({ schoolOptions: result.data.schools.map(({ school_code, school_name }) => ({ name: school_name, code: parseInt(school_code) })) })
		this.setState({ options: result.data })
		console.log("selectedschool", this.state.selectedSchool)
	}

	fetchUserData() {
		this.setState({ isLoading: true })
		apiGetUserList(1, 9999, this.state.searchKeyword, this.state.searchSchoolId).then(result => {
			console.log("fetchUserData", result.data)
			const usersData = result.data
			const mappedUsers = usersData.map((item, index) => ({
				id: item.id,
				number: index + 1,
				email: item.email,
				fullname: `
			${item.first_name}  
			${item.last_name}`,
				school: item.school ? item.school:"",
				school_area: item.school_area ? item.school_area:"",
				position: item.position,
				role: item.role ? item.role : "",
				is_enable: item.is_enable,
				platform_users: item.platform_users ? item.platform_users : ""
			}))
			this.setState({ users: mappedUsers, isLoading: false })
		});
	}

	handleSearchFormSubmit = (e) => {
		e.preventDefault()
		const formData = new FormData(e.target)
		const searchKeyword = formData.get("search_keyword")
		const searchSchoolId = this.state.selectedSchool.code
		this.setState({ searchKeyword, searchSchoolId})
		this.fetchUserData()
	}

	handleEditInfoSubmitted = async (updated_user) => {
		console.log("handleEditInfoSubmitted", updated_user)
		await apiAdminUpdateUser(this.state.editInfoUserId, updated_user);
		this.setState({ editInfoUserId: null })
		return this.fetchUserData()
	}

	handleEditPlatformRoleSubmitted = async (updated_platform_roles) => {
		console.log("handleEditPlatformRoleSubmitted", updated_platform_roles)
		for (let i = 0; i < updated_platform_roles.length; i++) {
			const platform_role = updated_platform_roles[i]
			if (platform_role.change_type == "create") {
				await apiAddPlatformUser({
					user_id: this.state.editPlatformUserId,
					platform_id: platform_role.platform_id,
					platform_role_id: platform_role.platform_role_id
				});
			} else if (platform_role.change_type == "update") {

				await apiUpdatePlatformUserRole(platform_role.user_platform_id, {
					platform_role_id: platform_role.platform_role_id
				});
			} else if (platform_role.change_type == "delete") {
				await apiDeletePlatformUser(platform_role.user_platform_id);
			}
		}
		this.setState({ editPlatformUserId: null })
		return this.fetchUserData()
	}

	handleDeleteUser = async (user_id) => {

		if (window.confirm("คุณต้องการลบผู้ใช้นี้ใช่หรือไม่?")) {
			await apiDeleteUser(user_id);
			return this.fetchUserData()
		}
	}

	renderSearchForm() {
		return <Form onSubmit={this.handleSearchFormSubmit}>
			<Row className='mt-3 mb-3 justify-content-between'>
				<Form.Label column="sm" sm="3" className="text-right" >
					E-mail / ชื่อ - นามสกุล
				</Form.Label>
				<Col sm="3">
					<Form.Control type="text" placeholder="E-mail / ชื่อ - นามสกุล" size="sm" name='search_keyword' />
				</Col>
				<Form.Label column="sm" sm="2" className="text-right">
					โรงเรียน
				</Form.Label>
				<Col sm="3">
					<Dropdown
						value={this.state.selectedSchool}
						onChange={(e)=> this.setState({ selectedSchool: e.value })}
						options={this.state.schoolOptions}
						optionLabel='name'
						filter
						filterBy='name'
						placeholder='เลือก ชื่อโรงเรียน'
						virtualScrollerOptions={{ itemSize: 25 }}
						className='w-full m-0'
						required
					/>
				</Col>
				<Col sm="1">
					<Button type='submit' variant="success"  size="sm">ค้นหา</Button>
				</Col>
			</Row>
		</Form>
	}

	responseStatus = async (detail,response) => {
		if (response.status === 200) {
			this.toast.current.show({
				severity: "success",
				summary: "Success",
				detail: `${detail} Successfully`,
				life: 3000,
			});
		} else {
			this.toast.current.show({
				severity: "error",
				summary: "Error",
				detail: `${detail} Fail`,
				life: 3000,
			});
		}
	}
	
	fullNameBodyTemplate =(rowData)=> {
		return (
			<div>
				{rowData.fullname}
				<br />
				<i>{rowData.email}</i>
			</div>
		);
	}
	schoolBodyTemplate =(rowData)=> {
		return (
			<div>
				{rowData.school ? rowData.school.school_code + " - " + rowData.school.school_name : "--"}
				<br />
				{rowData.school_area ? rowData.school_area.school_area_name : "--"}
			</div>
		);
	}
	roleBodyTemplate =(rowData)=> {
		return (
			<div>
				{rowData.position}
				<br />
				<i>{rowData.role ? rowData.role.role_display_name : "--"}</i>
			</div>
		);
	}
	platformBodyTemplate = (rowData) => {
		return (
			<div>
				{this.state.plarforms.map((platform, index) => {
					return (
						<div key={rowData.id + "-" + index}>
							<Badge bg={this.isUserInPlatform(rowData, platform) ? "success" : "secondary"} >
								{platform.name} {this.isUserInPlatform(rowData, platform) ? " : " + this.getUserPlatformRole(rowData, platform) : ""}
							</Badge>
						</div>
					);
				})}
			</div>
		);
	}
	statusBodyTemplate =(rowData)=> {
		return (
			<div>
				{rowData.is_enable ?
					<Badge className='p-2' bg="success" >Active</Badge> :
					<Badge className='p-2' bg="secondary" >Inactive</Badge>
				}
			</div>
		);
	}
	actionBodyTemplate =(rowData) => {
		return (
			<div>
				<Button className="mr-1" variant="success" size="sm" onClick={() => this.setState({editInfoUserId : rowData.id})}>
 					แก้ไข
 				</Button>
 				<Button className="mr-1" variant="success" size="sm" onClick={() => this.setState({editPlatformUserId : rowData.id})}>
 					Platform
 				</Button>
 				<Button className="mr-1" variant="danger" size="sm" onClick={ () => this.handleDeleteUser(rowData.id) }>
 					ลบ
 				</Button>
 				<Button className="mr-1" variant="success" size="sm" onClick={() => this.setState({showLogUserId : rowData.id})}>
 					Log
 				</Button>
			</div>
		);
	}
	renderHeader = () => {
		return (<div>{this.renderSearchForm()}</div>)		
	}

	renderUserTable() {
		return <DataTable
		stripedRows
		value={this.state.users}
		paginator
		header={this.renderHeader()}
		rows={10}
		dataKey='id'
		filters={this.state.filters}
		filterDisplay={this.state.showFilter ? 'row' : 'none'} // Conditional filter display
		loading={this.state.isLoading}
		globalFilterFields={['number', 'username', 'email', 'firstname', 'role']}
		emptyMessage='No user found.'>
		<Column field='number' header='#' sortable style={{ minWidth: '10rem', width: '7%', textAlign: 'center' }} />
		<Column field='fullname' header='ชื่อ - นามสกุล / Email' filter={this.state.showFilter} sortable filterPlaceholder='Search by Name' style={{ minWidth: '12rem', width: '20%' }} body={this.fullNameBodyTemplate} />
		<Column field='fullname' header='โรงเรียน / พื้นที่' filter={this.state.showFilter} sortable filterPlaceholder='Search by Name' style={{ minWidth: '12rem', width: '15%' }} body={this.schoolBodyTemplate} />
		<Column
			field='role'
			header='ตำแหน่ง / Role'
			showFilterMenu={false}
			style={{ minWidth: '14rem',width:'7%' }}
			body={this.roleBodyTemplate}
			filter={this.state.showFilter}
			sortable
		/>
		<Column	field='plarforms' header='Platform ที่เชื่อมโยง' body={this.platformBodyTemplate}	style={{ minWidth: '7rem' }}/>
		<Column	field='status'	header='สถานะ'	body={this.statusBodyTemplate} style={{ minWidth: '7rem' }}/>
		<Column field='action' header='จัดการ'  body={this.actionBodyTemplate} style={{ minWidth: '10rem' }}/>
	</DataTable>
		// <Table striped bordered hover size="sm">
		// 	<thead>
		// 		<tr>
		// 			<th>ชื่อ - นามสกุล / Email</th>
		// 			<th>โรงเรียน / พื้นที่</th>
		// 			<th>ตำแหน่ง / Role</th>
		// 			<th>Platform ที่เชื่อมโยง</th>
		// 			<th>สถานะ</th>
		// 			<th>จัดการ</th>
		// 		</tr>
		// 	</thead>
		// 	<tbody>
		// 		{
		// 			this.state.users.map((user, index) => {
		// 				return <tr key={index}>
		// 					<td>
		// 						{user.first_name} {user.last_name}
		// 						<br />
		// 						<i>{user.email}</i>
		// 					</td>
		// 					<td>
		// 						{user.school ? user.school.school_code + " - " + user.school.school_name : "--"}
		// 						<br />
		// 						{user.school_area ? user.school_area.school_area_name : "--"}
		// 					</td>
		// 					<td>
		// 						{user.position}
		// 						<br />
		// 						{user.role ? user.role.role_display_name : "--"}
		// 					</td>
		// 					{/* <td>
		// 						{
		// 							this.state.plarforms.map((platform, index) => {
		// 								return <div>
		// 									<Badge key={user.id + "-" + index} bg={this.isUserInPlatform(user, platform) ? "success" : "secondary"} >
		// 										{platform.name} {this.isUserInPlatform(user, platform) ? " : " + this.getUserPlatformRole(user, platform) : ""}
		// 									</Badge>
		// 								</div>
		// 							})
		// 						}
		// 					</td> */}
		// 					<td>{user.is_enable ?
		// 						<Badge bg="success" >Active</Badge> :
		// 						<Badge bg="secondary" >Inactive</Badge>
		// 					}</td>
		// 					<td>
		// 						<Button variant="primary" size="sm" onClick={() => this.setState({editInfoUserId : user.id})}>
		// 							แก้ไข
		// 						</Button>
		// 						<Button variant="primary" size="sm" onClick={() => this.setState({editPlatformUserId : user.id})}>
		// 							Platform
		// 						</Button>
		// 						<Button variant="danger" size="sm" onClick={ () => this.handleDeleteUser(user.id) }>
		// 							ลบ
		// 						</Button>
		// 						<Button variant="primary" size="sm" onClick={() => this.setState({showLogUserId : user.id})}>
		// 							Log
		// 						</Button>
		// 					</td>
		// 				</tr>
		// 			})
		// 		}
		// 	</tbody>
		// </Table>
	}
	render() {
		return <>
			<HeaderTop showProfile='true'></HeaderTop>
			<Container className='container-size' fluid>

				{
					this.state.isLoading &&
					<div className="text-center mt-5">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				}
				{
					!this.state.isLoading && this.renderUserTable()
				}

				<EditUserModal
					user_id={this.state.editInfoUserId}
					onClose={() => this.setState({ editInfoUserId: null })}
					onEdit={this.handleEditInfoSubmitted}
				/>

				<EditUserPlatformModal
					user_id={this.state.editPlatformUserId}
					onClose={() => this.setState({ editPlatformUserId: null })}
					onEdit={this.handleEditPlatformRoleSubmitted}
				/>

				<LogUserAccessPlatformModal
					user_id={this.state.showLogUserId}
					onClose={() => this.setState({ user_id: null })}
				/>

			</Container>
			<Footer />
		</>
	}

	isUserInPlatform(user, platform) {
		if (!user.platform_users) return false
		return user.platform_users.filter(p => p.platform_id === platform.id).length > 0
	}

	getUserPlatformRole(user, platform) {
		if (!user.platform_users) return false

		const userPlatform = user.platform_users.filter(p => p.platform_id === platform.id)
		return userPlatform.length > 0 ? userPlatform[0].platform_role.role_name : ""
	}

}