import React from 'react'
import { Card } from 'primereact'
import { getPlatformList } from '../../services/api/admin'
import { apiBatchUserVerify } from '../../services/api/admin'
import { apiBatchUserSync } from '../../services/api/admin'

import './style/auth_style.css'
import { Row, Col, Table, Form, Container, Badge,Button, } from 'react-bootstrap'
import HeaderTop from '../frame/header'
import Footer from '../frame/footer'

export default class UserPlatformBatchOperation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            frmBatchType: 'batch_sync',
            frmPlatformId: "",
            frmPlatformRoleId: "",
            frmUserInput: '',

            importState: 1,
            refUsers: [],
            platformRoleOptions: [],
            platformRoleListOptions: [],
            payload: null
        }

    }

    importType = [
        {
            value: 'batch_sync',
            label: 'Batch Sync User to Platform, (if user not exist, create new user)'
        },
        {
            value: 'batch_delete',
            label: 'Delete User From Platform'
        }
    ]

    componentDidMount() {
        this.getOptionInfo();
    }

    getOptionInfo = async () => {
        try {
            const result = await getPlatformList();

            const platformRoleListOptions = [];
            for (let i = 0; i < result.data.length; i++) {
                for (let j = 0; j < result.data[i].platform_roles.length; j++) {
                    platformRoleListOptions.push({
                        ...result.data[i].platform_roles[j],
                        platform_id: result.data[i].id
                    });
                }
            }
            console.log("platformRoleListOptions", platformRoleListOptions);
            this.setState({ platformRoleOptions: result.data, platformRoleListOptions: platformRoleListOptions });
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    handleImportSubmit = async (e) => {
        e.preventDefault();

        const user_emails = this.state.frmUserInput.split('\n').map((item) => item.trim()).filter((item) => item != '');

        const payload = {
            import_type: this.state.frmBatchType,
            platform_id: this.state.frmPlatformId,
            platform_role_id: this.state.frmPlatformRoleId,
            user_emails: user_emails
        }

        this.setState({ payload: payload });

        try {
            const result = await apiBatchUserVerify(payload);
            this.setState({ importState: 2, refUsers: result.data });
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    handleConfirmImport = async (e) => {
        e.preventDefault();

        try {
            const result = await apiBatchUserSync(this.state.payload);
            alert('Import Success');
            this.setState({ importState: 1, payload: null, refUsers: [] });
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    
    renderImportForm = () => {
        const elementGroupClassName ='offset-md-2 col-md-8 offset-lg-3 col-lg-6'
        return <Form onSubmit={this.handleImportSubmit}>
            <h4 className='p-3 text-center text-secondary'>อิมพอร์ตผู้ใช้งาน</h4>
						<hr className='bg-success'></hr>
            <Form.Group className={elementGroupClassName} controlId="importType">
                <Form.Label className='mt-2'>Import Type</Form.Label>
                <Form.Select
                    value={this.state.frmBatchType}
                    onChange={(e) => this.setState({ frmBatchType: e.target.value })}
                    disabled={this.state.importState == 2}
                >
                    {this.importType.map((item) => <option value={item.value} key={item.value}>{item.label}</option>)}
                </Form.Select>
            </Form.Group>
            <Form.Group className={elementGroupClassName} controlId="platformId">
                <Form.Label className='mt-2'>Platform</Form.Label>
                <Form.Select
                    value={this.state.frmPlatformId}
                    onChange={(e) => this.setState({ frmPlatformId: e.target.value })}
                    disabled={this.state.importState == 2}
                >
                    <option value={""}>Select Platform</option>
                    {this.state.platformRoleOptions.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </Form.Select>
            </Form.Group>
            {
                this.state.frmBatchType == 'batch_sync' &&
                <Form.Group className={elementGroupClassName} controlId="platformRoleId">
                    <Form.Label className='mt-2'>Role</Form.Label>
                    <Form.Select
                        value={this.state.frmPlatformRoleId}
                        onChange={(e) => this.setState({ frmPlatformRoleId: e.target.value })}
                        disabled={this.state.importState == 2}
                    >
                        <option value={""}>Select Role</option>
                        {this.state.platformRoleListOptions.filter((item) => item.platform_id == this.state.frmPlatformId).map((item) => <option value={item.id} key={item.id}>{item.role_name}</option>)}
                    </Form.Select>
                </Form.Group>
            }


            <Form.Group className={elementGroupClassName} controlId="userLists">
                <Form.Label className='mt-2'>Users</Form.Label>
                <Form.Control as="textarea" rows={3} value={this.state.frmUserInput} onChange={(e) => this.setState({ frmUserInput: e.target.value })} />
            </Form.Group>

            {
                this.state.importState == 1 && <div className='flex justify-content-center p-4'>
                    <Button variant="secondary" type="submit" onClick={this.handleImportSubmit}>
                    Begin Import
                </Button>
                </div>
            }

        </Form>
    }

    renderCheckUserForm = () => {
        return <>
            <div className='m-5'> 
                <Table striped bordered hover size='sm'>
                    <thead>
                        <tr>
                            <th>Input Email</th>
                            <th>Is Found</th>
                            <th>Current Status</th>
                            <th>Changed Status</th>
                            <th>Operation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.refUsers.map((item) => <tr key={item.email}>
                            <td>{item.email}</td>
                            <td>{item.is_found ? 'Yes' : 'No'}</td>
                            <td>{item.is_found && item.current_status}</td>
                            <td>{item.is_found && item.new_status}</td>
                            <td>{item.is_found && item.operation}</td>
                        </tr>)}
                    </tbody>
                </Table>
                <div className='text-center'> 
                    <Button className='mb-3 mr-1' variant="success" type="button" onClick={this.handleConfirmImport}>
                        Confirm Import
                    </Button>

                    <Button className='mb-3' variant="secondary" type="button" onClick={() => this.setState({ importState: 1, payload: null })}>
                        Cancel
                    </Button> 
                </div>
            </div>
        </>
    }

    render() {
        return <>
            <HeaderTop showProfile='true'></HeaderTop>
            <Container className='container-size' fluid>
                <Card>
                {
                    this.renderImportForm()
                }
                {
                    this.state.importState == 2 && this.renderCheckUserForm()
                }
                </Card>
            </Container>
            <Footer />
        </>
    }


}