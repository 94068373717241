import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import UserManual from './lib/components/archives/user_manual'
import Login from './components/auth/login'
import DashBoard from './components/dashboard/dashboard'
import NotFound from './components/http_error/404'
import lodash from 'lodash'
import Register from './components/auth/register'
import ForgetPassword from './components/auth/forget_password'
import UserManagement from './components/admin/user_management'
import UserRegister from './components/admin/user_register'
import UserListSkillMeo from './components/admin/user_list_skillmeo'
import UserListMooc from './components/admin/user_list_mooc'
import GoogleLogin from './components/auth/google'
import AuthRedeemForgorPasswordTokenFrm from './components/auth/redeem_forgot_password_token_frm'
import UserPlatformBatchOperation from './lib/components/auth/users_platform_batch_operation'
import UserList from './components/admin/user_list_sso'
import UserTableSSO from './lib/components/auth/users_table_sso'

const dayjs = require('dayjs')

const App = () => {
	console.log('Router called..', localStorage.getItem('token'))

	// Validate if token is expired
	let expired_at = localStorage.getItem('expire')
	if (expired_at) {
		if (dayjs().isAfter(dayjs(expired_at))) {
			localStorage.removeItem('expire')
			localStorage.removeItem('token')
			localStorage.removeItem('user_info')
		}
	} else {
		localStorage.removeItem('expire')
		localStorage.removeItem('token')
		localStorage.removeItem('user_info')
	}

	let user_info = null
	let is_admin = false
	if (localStorage.getItem('user_info')) {
		try {
			user_info = JSON.parse(localStorage.getItem('user_info'))
			console.log('user_info router...', user_info)

			let chk_role = lodash.filter(user_info.roles, { id: 1 })
			if (chk_role.length > 0) is_admin = true
		} catch (e) {}

		console.log('is_admin : ', is_admin)
	}

	return (
		<Router>
			<Routes>
				{localStorage.getItem('token') ? (
					<>
						<Route path='/' element={<DashBoard />} />
						<Route path='/app/dashboard' element={<DashBoard />} />
						<Route path='/admin/user_management' element={<UserList />} />
						<Route path='/admin/user_platform_batch' element={<UserPlatformBatchOperation />} />

						<Route path='/app/user_management' element={<UserManagement />} />
						<Route path='/app/user_register' element={<UserRegister />} />
						<Route path='/app/user_list_mooc' element={<UserListMooc />} />
						<Route path='/app/user_list_skillmeo' element={<UserListSkillMeo />} />
						<Route path='/usermanual' element={<UserManual />} />
						<Route path='*' element={<NotFound />} />
					</>
				) : (
					<>
						<Route path='/' element={<Login />} />
						<Route path='/google_login/auth/google' element={<GoogleLogin />} />
						<Route path='/login' element={<Login />} />
						
                         { process.env.REACT_APP_ALLOW_SELF_REGISTER === 'true' && 
                             <>
                                 <Route path='/register' element={<Register />} />
                                 <Route path='/forgot_password' element={<ForgetPassword />} />
                                 <Route path='/forgot_password/:token' element={<AuthRedeemForgorPasswordTokenFrm />} />
                             </>
                         }
 
						<Route path='/usermanual' element={<UserManual />} />
						<Route path='*' element={<NotFound />} />
					</>
				)}
			</Routes>
		</Router>
	)
}

export default App
