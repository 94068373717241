import React from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { apiGetUserPlatformAccessLog } from '../../../services/api/admin';
import dayjs from 'dayjs';
// prop userId, onClose, onEdit

class LogUserAccessPlatformModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.user_id != null,
            accessLogs: null,
            isLoading: true,

        };
    }

    elementGroupClassName = 'flex flex-column gap-2 mb-2'

    titleOption = [
        {
            en: 'Mr.',
            th: 'นาย',
        },
        {
            en: 'Ms.',
            th: 'นาง/นางสาว',
        },
    ]


    componentDidMount() {

        if (this.props.user_id != null) {
            this.getUserAccessLog();
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.user_id !== prevProps.user_id) {
            this.setState({ showModal: this.props.user_id != null });
            if (this.props.user_id != null) {
                this.getUserAccessLog();
            }
        }
    }

    getUserAccessLog = () => {
        this.setState({ isLoading: true });
        apiGetUserPlatformAccessLog(this.props.user_id).then((result) => {
            this.setState({ accessLogs: result.data, isLoading: false });
        });
    }

    handleClose = () => {
        this.setState({ showModal: false, accessLogs: null });
        this.props.onClose();
    };



    render() {
        const { accessLogs, showModal } = this.state;



        return <>
            {(accessLogs != null) &&
                <Modal show={showModal} onHide={this.handleClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>User Access Platform Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover>

                            <thead>
                                <tr>
                                    <th>Access At</th>
                                    <th>Platform Name</th>
                                    <th>Client Info</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    accessLogs.map((log, index) => {
                                        return <tr key={index}>
                                            <td>{dayjs(log.access_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{log.platform_user.platform.name}</td>
                                            <td>
                                                <div>
                                                    <div><b>agent:</b> {log.client_info.agent}</div>
                                                    <div><b>ip:</b> {log.client_info.ip}</div>
                                                    <div><b>referrer:</b> {log.client_info.referrer}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>

            }
        </>
    }
}

export default LogUserAccessPlatformModal;