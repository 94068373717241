import React, { Component, useState, useEffect } from 'react'
import { apiGetAuthorizeCode, apiLogout } from '../../lib/services/api/auth'
import HeaderTop from '../../lib/components/frame/header'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Footer from '../../lib/components/frame/footer'
import { apiGetInfo } from '../../lib/services/api/user'

const Dashboard = () => {
	//load system admin role id from env REACT_APP_SYSTEM_ADMIN_ROLE_ID
	const SYSTEM_ADMIN_ROLE_ID = process.env.REACT_APP_SYSTEM_ADMIN_ROLE_ID;

	const [isAdmin, setIsAdmin] = useState(false)
	const [loading, setLoading] = useState(true)
	const [plarformPath, setPlarformPath] = useState(null)
	const loadMyInfoSetAdmin = async () => {
		try {
			let user_info = JSON.parse(localStorage.getItem('user_info'))
			if (!user_info || !plarformPath) {
				const res = await apiGetInfo()
				console.log(res)
				if (res && res.status === 200) {
					user_info = res.data
					setPlarformPath(user_info.platform_users)
				}
				setPlarformPath(user_info.platform_users)
				setIsAdmin(user_info.role_id.toString() === SYSTEM_ADMIN_ROLE_ID)
				setLoading(false)
			}
			
		} catch (e) {
			console.log('Error', e)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			await loadMyInfoSetAdmin()
		}
		  
		  fetchData()
	}, [])

	const imgClick = async (url,name, is_sso) => {
		console.log('url', url)
		console.log('is_sso', is_sso)
		let authorize_code
		if(is_sso == true){
			if(name == 'SKILLMEO V2') {
				authorize_code = (await apiGetAuthorizeCode(process.env.REACT_APP_SKILLMEO_APP_CODE)).data.authorize_code
			} else {
				authorize_code = (await apiGetAuthorizeCode(process.env.REACT_APP_MOOC_APP_CODE)).data.authorize_code
			}
			window.open(
				url + '?authorize_code=' + encodeURIComponent(authorize_code) + '&token=' + encodeURIComponent(localStorage.getItem('token')),
				'_blank'
			)
		} else {
			window.open(url, '_blank')
		}
	}

	const imgAdminClick = async (id) => {
		console.log('id', id)
		if(id == 'user_management'){
			setTimeout(() => {
				window.location.assign('/app/user_management')
			}, 1000)
		} else {
			setTimeout(() => {
		 		window.location.assign('/app/user_register')
		 	}, 1000)
		}
	}

	return (
		<>
			<HeaderTop showProfile='true'></HeaderTop>
			<Container className='container-size'  fluid>
				<Row className='bg-white' xs={1} md={2}>
					<Col className='text-center'>
						<Image src='/shared/main_icon.png' className='w-75' alt='' />
					</Col>
					<Col>
						<div className='text-container mt-4 mb-4'>
							<h2>ยินดีต้อนรับสู่ Bangkok Learning Hub</h2>
							พัฒนาศักยภาพส่วนบุคคล เพื่อประสิทธิผลองค์รวมขององค์กร ผู้ใช้งานสามารถศึกษาการใช้งาน โดยการดาวน์โหลดคู่มือการใช้งานจากเมนูด้านบน
						</div>
						<Row className='mb-5 ' sm={2} md={2} xl={4}>
							{plarformPath?.map((platforms,index)=>{
								return <Col className='grid-item'  onClick={() => imgClick(platforms.platform.redirect_url,platforms.platform.name,platforms.platform.is_sso)}>
								<Image src={platforms.platform.logo_path} alt='' className={!loading && platforms.platform.logo_path !==null ? "img" : "border-none"} style={{cursor:"pointer"}}/>
							</Col>
							})}

							{isAdmin ? (
								<Col className='grid-item' onClick={() => imgAdminClick('user_management')}>
									<Image src='/user_management.png' alt=''  className={!loading ? "img" : "border-none"} style={{cursor:"pointer"}} />
								</Col>
							) : null}

							{isAdmin ? (
								<Col className='grid-item' onClick={() => imgAdminClick('user_register')}>
									<Image src='/user_register.png' alt='' className={!loading ? "img" : "border-none"} style={{cursor:"pointer"}} />
								</Col>
							) : null}
						</Row>
					</Col>
				</Row>
			</Container>
			<Footer></Footer>
		</>
	)
}

export default Dashboard
