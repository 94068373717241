import React from 'react'
import HeaderTop from '../../lib/components/frame/header'
import { Container } from 'react-bootstrap'
import Footer from '../../lib/components/frame/footer'
import UserTable from '../../lib/components/auth/users_table'


const UserList = ()  => {
    return (
        <>
            <HeaderTop showProfile='true'></HeaderTop>
            <Container className='container-size' fluid>
                <UserTable props="SSO"  />
            </Container>
            <Footer/>
        </>
    )
}

export default UserList
