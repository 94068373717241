import React from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { apiSendForgotPassword } from '../../lib/services/api/auth'
import './style/login.css'
import { Button, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from '../../lib/components/frame/footer'
import HeaderTop from '../../lib/components/frame/header'
import { InputText } from 'primereact/inputtext'
import { Card } from 'primereact/card'
import { Message } from 'primereact/message'
import 'primereact/resources/themes/lara-light-indigo/theme.css'

const ForgetPassword = () => {
	const elementGroupClassName = 'flex flex-column gap-2 mb-2'
	const [email, setEmail] = useState('')
	const [isComplete, setIsComplete] = useState(false)

	const myChangeHandler = (event) => {
		const { value } = event.target
		setEmail(value)
	}

	const activeForgotPassword = async () => {

		if (email.length === 0) {
			Swal.fire({
				icon: 'error',
				text: 'กรุณาระบุอีเมล์',
			})
			return
		}

		try {
		 await apiSendForgotPassword(email)

		} catch (e) {
			console.error('e', e)
		}

		setIsComplete(true)
	}

	return (
		<>
			<HeaderTop activeMenu={'login'}></HeaderTop>
			<Container className='container-size' fluid>
				<Card>
					<h4 className='p-3 text-center text-secondary'>ลืมรหัสผ่าน</h4>
					<hr className='bg-success'></hr>
					{isComplete ? (
						<div className={elementGroupClassName}>
							<Message className='mt-2 mb-4'
								severity='success'
								icon
								text="เราได้ส่งอีเมล์ลิงก์สำหรับรีเซ็ตรหัสผ่านถึงคุณแล้ว ควรปรากฏในกล่องจดหมายของคุณภายในห้านาทีถัดไป หากคุณยังไม่เห็น โปรดตรวจสอบที่ไฟลเดอร์สแปมของคุณ !!'"
							/>
						</div>
					) : (
						<div>
							<div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
								<div className={elementGroupClassName}>
									<Message severity='success' text='ระบุที่อยู่อีเมลที่คุณใช้ และเราจะส่งคำแนะนำในการรีเซ็ตรหัสผ่านของคุณ' />
								</div>
								<div className={elementGroupClassName}>
									<label htmlFor='email'>อีเมล์</label>
									<InputText id='email' type='email' onChange={myChangeHandler} />
								</div>
							</div>
							<div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
								<div className='flex justify-content-center p-4'>
									<Button variant='secondary' type='button' className='btn-register-submit' onClick={activeForgotPassword}>
										ส่งอีเมล์คำแนะนำ
									</Button>
								</div>
							</div>
						</div>
					)}
				</Card>
			</Container>
			<Footer></Footer>
		</>
	)
}

export default ForgetPassword
